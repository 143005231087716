<template>
    <div class="popUpForm multiplePopUpForms" v-loading="loading">
        <div class="content">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <template v-for="(item,index) in forms">
                    <el-tab-pane
                        :key="index"
                        :label="item.FormName"
                        :name="item.FormName"
                        :disabled="judgeDisable(item,index)"
                    >
                        <span slot="label"><i class="iconfont iconbiaodan"></i>{{ item.FormName }}</span>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <div class="formContent">
                <formPage :form-data="currentForm"></formPage>
            </div>
        </div>
        <footer>
            <el-button
                type="primary"
                class="save"
                @click="save"
                :loading="saveBtnLoading"
                v-if="isReadOnly!=='false'"
            >
                保存
            </el-button>
            <el-button class="Close" @click="Close">
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import formPage from '@/components/form/formPage';
import store from '@/store';

export default {
    components: {
        formPage,
    },
    props: {
        extr: {
            type: [Array, Object, String],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 默认展开tab页
            activeName: '',
            // 附件状态
            isDisabled: true,
            // 当前表单ID
            formId: '',
            forms: [], // 表单集合
            currentForm: {}, // 当前表单对象
            thisFormOnly: '',
            isReadOnly: 'false',
            // 保存按钮状态
            saveBtnLoading: false,
        };
    },
    created() {
        this.forms = this.extr.formList;
        this.currentForm = this.forms[0];
        this.activeName = this.forms[0].FormName;
        // 当前表单唯一标识赋值
        this.thisFormOnly = this.$queryString(this.currentForm.FormUrl, 'FormCode');
        this.isReadOnly = this.$queryString(this.currentForm.FormUrl, 'IsView');
    },
    methods: {
        // 切换页签
        handleClick(tab) {
            this.currentForm = this.forms[tab.index];
            this.thisFormOnly = this.$queryString(this.currentForm.FormUrl, 'FormCode');
            this.isReadOnly = this.$queryString(this.currentForm.FormUrl, 'IsView');
        },
        // 第一个表单保存后，后边的可点
        judgeDisable(data, index) {
            let b = false;
            if (index !== 0) {
                const PKValues = this.$queryString(data.FormUrl, 'PKValues');
                if (!PKValues) {
                    b = true;
                }
            }
            return b;
        },
        // 保存表单
        save() {
            this.saveBtnLoading = true;
            const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess, this.errorCallBack);
        },
        // 保存表单成功回调
        JTFlowAfterFormSaveSuccess(PKValues) {
            this.saveBtnLoading = false;
            this.forms.map(item => {
                item.FormUrl += '&PKValues=' + PKValues.PKValues;
            });
            store.state.currentOpenList.forEach(item => {
                item.tableObj.updateTable();
            });
        },
        // 保存失败回调
        errorCallBack() {
            this.saveBtnLoading = false;
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.popUpForm
    height 100%
    .content
        height calc(100% - 0.6rem)
        .el-tabs
            width 100%
            height 0.46rem
        .formContent
            background: #fff
            height calc(100% - 0.46rem)
</style>
